import React from 'react'
import { gsap, ScrollTrigger } from 'helpers/gsap'
import { Wrapper } from './index.styled'
import { useHeaderColor } from 'context'

const HeaderTransition = ({ status, children }) => {
  const refWrapper = React.useRef(null)
  const gsapContextRef = React.useRef(null)
  const { setIsMenuLight, setIsLogoLight } = useHeaderColor()

  React.useLayoutEffect(() => {
    if (!gsapContextRef.current && status === 'entered') {
      gsapContextRef.current = gsap.context(() => {
        const darkLogoSections = gsap.utils.toArray('.light-logo-section')
        darkLogoSections.forEach((section, index) => {
          ScrollTrigger.create({
            trigger: section,
            start: `top top${index === 0 ? '+=100px' : ''}`,
            onEnter: () => setIsLogoLight(true),
            onLeave: () => setIsLogoLight(false),
            onEnterBack: () => setIsLogoLight(true),
            onLeaveBack: () => setIsLogoLight(false)
          })
        })

        const darkMenuButtonsSections = gsap.utils.toArray(
          '.light-buttons-section'
        )
        darkMenuButtonsSections.forEach((section, index) => {
          ScrollTrigger.create({
            trigger: section,
            start: `top top${index === 0 ? '-=100px' : ''}`,
            onEnter: () => setIsMenuLight(true),
            onLeave: () => setIsMenuLight(false),
            onEnterBack: () => setIsMenuLight(true),
            onLeaveBack: () => setIsMenuLight(false)
          })
        })
      }, refWrapper)
    } else if (
      gsapContextRef.current &&
      (status === 'exiting' || status === 'exited')
    ) {
      gsapContextRef.current.revert()
    }
    return () =>
      gsapContextRef.current ? gsapContextRef.current.revert() : null
  }, [status])

  return <Wrapper ref={refWrapper}>{children}</Wrapper>
}

export default HeaderTransition
