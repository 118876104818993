import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    
  }

  body {
    margin: 0;
    padding: 0;
    width: 100vw;
    background: rgba(243, 242, 240, 0.9);
    color: #000000;
    overflow-x: hidden;
    &.overflow-hidden {
      height: 100vh;
      overflow: hidden;
    }
  }
  body > div {width: 100%;}
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {

    font-weight: 100;
  }
  body,
  p,
  a,
  button,
  ul,
  form {

    font-weight: normal;
  }
  a {
    color: inherit;
    text-decoration: none;
    &.underline {
      text-decoration: underline;
    }
  }
  .center {
    text-align: center;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .italics {
    font-style: italic;
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }
  .ReactModal__Overlay--after-open{
      opacity: 1;
  }
  .ReactModal__Overlay--before-close{
      opacity: 0;
  }
  .only-mobile {
    display: none !important ; 
    @media screen and (max-width: 1023px){
      display: block !important;
    }
  }
  .only-mobile-inline {
    display: none !important ; 
    @media screen and (max-width: 1023px){
      display: inline !important;
    }
  }
  .only-mobile-flex {
    display: none !important ; 
    @media screen and (max-width: 1023px){
      display: flex !important;
    }
  }
  .only-desktop {
     @media screen and (max-width: 1023px){
      display: none !important;
    }
  }
`
