import { Link } from 'gatsby'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/media-queries'
import MobileLogo from 'icons/mobile-header-logo.svg'

export const HeaderWrapper = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 46px 36px 0;
  z-index: 99;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
  ${mediaMax('tabletLandscape')} {
    padding: 0;
    position: relative;
  }
`

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
  ${mediaMax('tabletLandscape')} {
    padding: 40px 30px 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #f3f2f0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: transform 1s, height 0.25s;
    border-bottom: 1px solid #000;
    &.hidden {
      transform: translateY(-200px);
    }
    &.top-position {
      height: 101px;
    }
  }
`

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  color: ${({ isMenuLight }) => (isMenuLight ? '#fff' : '#000')};

  &.active {
    color: #000;
  }
  ${mediaMax('tabletLandscape')} {
    color: #000;
    position: relative;
    justify-content: space-between;
    width: 100%;
  }
`

export const HeaderContactLink = styled(Link)`
  display: block;
  margin-right: 25px;
  font-family: 'Trade-Gothic', san-serif;
  font-weight: 700;
  font-size: ${calculateResponsiveSize(18)};
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: inherit;
  padding-top: ${calculateResponsiveSize(4)};

  ${mediaMax('tabletLandscape')} {
    font-size: 10px;
    line-height: 12px;
    margin-right: 0;
  }
`

export const HeaderMenuButton = styled.button`
  display: inline-block;
  cursor: pointer;
  border: none;
  background: transparent;
  color: inherit;
  .line {
    display: block;
    width: 35px;
    height: 3px;
    background-color: currentColor;
    margin: 3px 0;
    transition: 0.4s;
  }
  .invisible-line {
    background: transparent;
  }
  ${({ $active }) =>
    $active
      ? `
  .line-top {
    transform: rotate(-45deg) translate(-5px, 3px);
  }
  .line-bottom {
    transform: rotate(45deg) translate(-5px, -3px);
  }`
      : ''};
`

export const HeaderLogoWrapper = styled(Link)`
  transition: 1s transform;

  svg {
    width: ${calculateResponsiveSize(195)};
    height: ${calculateResponsiveSize(122)};
  }
  &.hidden {
    transform: translateY(-300%);
  }
  color: #ffffff;

  ${mediaMax('tabletLandscape')} {
    display: none;
  }
`
export const HeaderLogoSvgWrapper = styled.span`
  color: #000;
  color: ${({ isLogoLight }) => (isLogoLight ? '#fff' : '#000')};
`

export const HeaderLogoMobile = styled(Link)`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 22px;
  height: 33px;
  background: url(${MobileLogo}) no-repeat;
`

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f3f2f0;
  text-align: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 50vw;
  height: 100vh;
  transition: transform 0.3s;
  ${mediaMax('tabletLandscape')} {
    padding-top: 200px;
    width: 100%;
    overflow: auto;
  }
  &.entering,
  &.entered {
    transform: translateX(0);
  }
  &.exiting,
  &.exited {
    transform: translateX(100%);
  }
`

export const MenuContent = styled.ul`
  margin-top: auto;
`

export const MenuItemWrapper = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  justify-content: center;
  ${mediaMax('tabletLandscape')} {
    align-items: center;
  }
`

export const MenuItem = styled(Link)`
  margin-bottom: ${calculateResponsiveSize(8)};
  font-family: 'Trade-Gothic', san-serif;
  font-weight: 700;
  font-size: ${calculateResponsiveSize(16)};
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.065em;
  text-transform: uppercase;
  color: #000000;
  ${mediaMax('tabletLandscape')} {
    font-size: 14px;
    margin-bottom: 8px;
    max-width: 60%;
  }
`

export const SubMenuItem = styled(MenuItem)`
  font-size: ${calculateResponsiveSize(12)};
  line-height: ${calculateResponsiveSize(26)};
  ${mediaMax('tabletLandscape')} {
    font-size: 10px;
    line-height: 12px;
  }
`

export const MenuFooter = styled.footer`
  display: flex;
  max-width: 20vw;
  width: 100%;
  justify-content: space-evenly;
  padding-bottom: 100px;
  margin-top: auto;
  ${mediaMax('tabletLandscape')} {
    flex-direction: column;
    max-width: 100%;
  }
`
export const MenuFooterLink = styled.span`
  a {
    font-family: 'Trade-Gothic', san-serif;
    font-weight: 700;
    font-size: ${calculateResponsiveSize(12)};
    line-height: ${calculateResponsiveSize(26)};
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #231f20;
    &:nth-child(2) {
      margin: 0 10px;
    }
    ${mediaMax('tabletLandscape')} {
      font-size: 10px;
      line-height: 12px;
      &:nth-child(2) {
        margin: 10px;
      }
    }
  }
`
