import React from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'
import PropTypes from 'prop-types'
import Header from 'components/header'
import Footer from 'components/footer'
import HeaderTransition from 'components/header-transition'
import { GlobalStyle } from 'styles'
import {
  LayoutWrapper,
  PageWrapper,
  ContentWrapper,
  HideBlock
} from './index.styled'
import { gsap, ScrollSmoother } from 'helpers/gsap'
import { HeaderProvider } from 'context'

import 'styles/fonts.css'

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 250, exit: 250 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <LayoutWrapper id='layoutWrapper'>
              <HeaderTransition status={status}>{children}</HeaderTransition>
            </LayoutWrapper>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

const LayoutContainer = ({ children, location }) => {
  const refWrapper = React.useRef(null)

  const withoutGsapSmoothScroll =
    location.pathname === '/legal/' || location.pathname === '/privacy-notice/'

  React.useLayoutEffect(() => {
    if (!withoutGsapSmoothScroll) {
      const gsapContext = gsap.context(() => {
        const scrollerSmoother = ScrollSmoother.create({
          content: '.home-content',
          wrapper: '.home-wrapper',
          smooth: 2,
          effects: true
        })
      }, refWrapper)

      return () => gsapContext.revert()
    }
  }, [children, withoutGsapSmoothScroll])

  return (
    <div className='home-wrapper' ref={refWrapper}>
      <Header />
      <div className='home-content'>
        <PageWrapper>
          <GlobalStyle />
          <ContentWrapper>
            <LayoutContent location={location}>{children}</LayoutContent>
          </ContentWrapper>
        </PageWrapper>
        <HideBlock hide={withoutGsapSmoothScroll}>
          <Footer />
        </HideBlock>
      </div>
    </div>
  )
}

const Layout = (props) => {
  return (
    <HeaderProvider>
      <LayoutContainer {...props} />
    </HeaderProvider>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired
}

export default Layout
