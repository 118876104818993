import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Logo } from 'icons/logo'
import { useHeaderColor } from 'context'
import { CSSTransition } from 'react-transition-group'
import {
  HeaderWrapper,
  MenuWrapper,
  MenuItemWrapper,
  MenuItem,
  SubMenuItem,
  HeaderContactLink,
  HeaderContent,
  HeaderLogoWrapper,
  HeaderButtonsWrapper,
  HeaderMenuButton,
  MenuFooter,
  MenuContent,
  MenuFooterLink,
  HeaderLogoMobile,
  HeaderLogoSvgWrapper
} from './index.styled'

const Menu = ({ menu, className, socialLinks, onClick }) => {
  return (
    <>
      <MenuWrapper className={className}>
        <MenuContent>
          {menu.items.map((item, index) => (
            <MenuItemWrapper
              className={item.title === 'home' ? 'only-mobile-flex' : ''}
              key={`menu-${index}`}
              onClick={onClick}
            >
              <MenuItem aria-label={item.title} to={item.link}>
                {item.title}
              </MenuItem>
              {item.subMenu?.map((subItem, index) => (
                <SubMenuItem
                  aria-label={subItem.title}
                  to={subItem.link}
                  key={`submenu-${index}`}
                >
                  {subItem.title}
                </SubMenuItem>
              ))}
            </MenuItemWrapper>
          ))}
        </MenuContent>
        <MenuFooter>
          {socialLinks.items.map((item, index) => (
            <MenuFooterLink key={`menu-footer-${index}`}>
              {item.title === 'gallery'
                ? (
                  <Link aria-label={item.title} to={item.link} onClick={onClick}>
                    {item.title}
                  </Link>
                  )
                : (
                  <a
                    aria-label={item.title}
                    href={item.link}
                    target='_blank'
                    onClick={onClick} rel='noreferrer'
                  >
                    {item.title}
                  </a>
                  )}
            </MenuFooterLink>
          ))}
        </MenuFooter>
      </MenuWrapper>
    </>
  )
}

const Header = () => {
  const { isLogoLight, isMenuLight } = useHeaderColor()
  const content = useStaticQuery(graphql`
    query {
      dataJson {
        menu {
          items {
            link
            subMenu {
              title
              link
            }
            title
          }
        }
        socialLinks {
          items {
            title
            link
          }
        }
      }
    }
  `)

  const { menu, socialLinks } = content.dataJson

  const [isOpen, setIsOpen] = React.useState(false)

  const [scrollDirection, setScrollDirection] = React.useState(null)
  const [mobileHeaderDefaultPosition, setmobileHeaderDefaultPosition] =
    React.useState(true)

  React.useEffect(() => {
    let lastScrollY = window.pageYOffset

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset
      const direction = scrollY > lastScrollY ? 'down' : 'up'
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0

      if (lastScrollY === 0) {
        setmobileHeaderDefaultPosition(true)
      } else {
        setmobileHeaderDefaultPosition(false)
      }
    }

    window.addEventListener('scroll', updateScrollDirection)
    return () => {
      window.removeEventListener('scroll', updateScrollDirection)
    }
  }, [scrollDirection, mobileHeaderDefaultPosition])

  React.useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden')
    }

    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  return (
    <HeaderWrapper id='header'>
      <HeaderContent
        className={[
          scrollDirection === 'down' ? 'hidden' : ' ',
          mobileHeaderDefaultPosition && 'top-position'
        ]}
      >
        <HeaderLogoWrapper
          aria-label='logo'
          to='/'
          className={[scrollDirection === 'down' ? 'hidden' : '']}
        >
          <HeaderLogoSvgWrapper isLogoLight={isLogoLight}>
            <Logo />
          </HeaderLogoSvgWrapper>
        </HeaderLogoWrapper>
        <HeaderButtonsWrapper
          isMenuLight={isMenuLight}
          className={[isOpen && 'active']}
        >
          <HeaderContactLink
            aria-label='contacts'
            to='/contacts'
            onClick={() => setIsOpen(false)}
          >
            INQUIRE
          </HeaderContactLink>
          <HeaderLogoMobile to='/' className='only-mobile' />
          <HeaderMenuButton
            aria-label='menu button'
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            $active={isOpen}
          >
            <span className='line line-top' />
            <span className='line invisible-line' />
            <span className='line line-bottom' />
          </HeaderMenuButton>
        </HeaderButtonsWrapper>
      </HeaderContent>
      <CSSTransition
        in={isOpen}
        timeout={{ enter: 1000, exit: 1000 }}
        unmountOnExit
      >
        {(state) => (
          <Menu
            onClick={() => setIsOpen(false)}
            menu={menu}
            socialLinks={socialLinks}
            className={`${state}`}
          />
        )}
      </CSSTransition>
    </HeaderWrapper>
  )
}

export default Header
