import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, ScrollSmoother)

export * from 'gsap'
export * from 'gsap/ScrollTrigger'
export * from 'gsap/ScrollSmoother'
